import Index from './components/Index.vue';
import Category from './components/library/Category.vue';
import Daily from './components/discovery/Daily.vue';
import Collection from "./components/discovery/Collection.vue";
import Gallery from './components/discovery/Gallery.vue';
import Jigsaw from './components/discovery/Jigsaw.vue';
import CollectionEvent from './components/discovery/CollectionEvent.vue';
import DiscoveryView from './components/discovery/View.vue';
import Update from './components/library/Update.vue';
import Rookie from './components/library/Rookie.vue';
import RookieStat from './components/library/RookieStat.vue';
import LibraryView from './components/library/View.vue';
import Level from './components/discovery/Level.vue';
import Carousel from './components/library/Carousel.vue';
import EventView from './components/event/View.vue';
import Bonus from './components/event/Bonus.vue';
import Suspend from './components/library/Suspend.vue';
import Popup from './components/library/Popup.vue';
import Score from './components/Score.vue';
import Tag from './components/Tag.vue';
import Auth from './components/Auth.vue';
import Subscription from './components/purchase/Subscription.vue';
import Promotion from './components/library/Promotion.vue';
import PartTime from './components/PartTime.vue';
import Debug from './components/Debug.vue';
import NewbieSales from "./components/purchase/NewbieSales.vue";

export const routes = [
    {
        name: 'Index',
        path: '/',
        component: Index,
    }, {
        name: 'Category',
        path: '/category',
        component: Category,
    }, {
        name: 'Daily',
        path: '/daily',
        component: Daily,
    }, {
        name: 'Collection',
        path: '/collection',
        component: Collection,
    }, {
        name: 'Gallery',
        path: '/gallery',
        component: Gallery,
    }, {
        name: 'DiscoveryView',
        path: '/discovery',
        component: DiscoveryView,
    }, {
        name: 'Update',
        path: '/update',
        component: Update,
    }, {
        name: 'Rookie',
        path: '/rookie',
        component: Rookie,
    }, {
        name: 'RookieStat',
        path: '/rookie/stat',
        component: RookieStat,
    }, {
        name: 'LibraryView',
        path: '/library',
        component: LibraryView,
    }, {
        name: 'Jigsaw',
        path: '/jigsaw',
        component: Jigsaw,
    }, {
        name: 'Level',
        path: '/level',
        component: Level,
    }, {
        name: 'CollectionEvent',
        path: '/collection-event',
        component: CollectionEvent,
    }, {
        name: 'Carousel',
        path: '/carousel',
        component: Carousel,
    }, {
        name: 'EventView',
        path: '/event',
        component: EventView,
    }, {
        name: 'Bonus',
        path: '/bonus',
        component: Bonus,
    }, {
        name: 'Suspend',
        path: '/suspend',
        component: Suspend,
    }, {
        name: 'Popup',
        path: '/popup',
        component: Popup,
    }, {
        name: 'Score',
        path: '/score',
        component: Score,
    }, {
        name: 'Tag',
        path: '/tag',
        component: Tag,
    }, {
        name: 'Auth',
        path: '/auth',
        component: Auth,
    }, {
        name: 'Subscription',
        path: '/purchase/subscription',
        component: Subscription,
    }, {
        name: 'NewbieSales',
        path: '/purchase/newbie-sales',
        component: NewbieSales,
    }, {
        name: 'Promotion',
        path: '/promotion',
        component: Promotion,
    }, {
        name: 'PartTime',
        path: '/part-time',
        component: PartTime,
    }, {
        name: 'Debug',
        path: '/debug',
        component: Debug,
    },
];