<template>
    <div>
      <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading">
        <el-table-column label="活动名称" prop="name" width="150"></el-table-column>
        <el-table-column label="SKU" prop="sku" width="150"></el-table-column>
        <el-table-column label="活跃" prop="active" width="100">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :disabled="loading" @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>

        <el-table-column style="margin-left: 10px" label="Banner" width="250" prop="resource">
          <template #default="scope">
            <el-image v-if="scope.row.banner" lazy :src="`${$root.env.CDN_URL}/${scope.row.banner}`"
                      style="width: 250px"></el-image>
          </template>
        </el-table-column>

        <el-table-column label="周期" prop="period" width="60">
            <template #default="scope">
                {{ period_enum[scope.row.period] }}
            </template>
        </el-table-column>
        <el-table-column label="主要文字" prop="primary" width="100"></el-table-column>
        <el-table-column label="开始天数" prop="start" width="120"></el-table-column>
        <el-table-column label="结束天数" prop="end" width="120"></el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <el-row>
            <el-col :span="10">
                <el-form :model="form" ref="form" label-width="100px">
                    <el-form-item label="活动名称" prop="name" :rules="[{required: true, message: '请输入活动名称'}]">
                      <el-input v-model="form.name" :disabled="loading"></el-input>
                    </el-form-item>
                    <el-form-item label="SKU" prop="sku" :rules="[{required: true, message: '请输入SKU'}]">
                        <el-input v-model="form.sku" :disabled="loading || editing"></el-input>
                    </el-form-item>
                    <el-form-item label="周期" prop="period" :rules="[{required: true, message: '请选择周期'}]">
                      <el-select v-model="form.period" :disabled="loading">
                        <el-option v-for="(v, k) in period_enum" :label="v" :value="parseInt(k)"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="Banner" prop="banner" :rules="[{required: false, message: '请上传图片'}]">
                      <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleBannerSuccess"
                                 accept="image/*" :data="{prefix: 'sales'}" :onProgress="handleProgress"
                                 :headers="{authorization: $root.authorization}">
                        <img v-if="form.banner" :src="`${$root.env.CDN_URL}/${form.banner}`" style="width: 200px">
                        <el-button size="small" v-else :loading="loading">上传图片</el-button>
                      </el-upload>
                    </el-form-item>
                  <!--                    <el-form-item label="试用期" prop="free_trial">-->
<!--                        <el-input-number v-model="form.free_trial" disabled></el-input-number>-->
<!--                    </el-form-item>-->
                    <el-form-item label="标题" prop="header" :rules="[{required: true, message: '请输入标题'}]">
                        <el-input v-model="form.header" :disabled="loading"></el-input>
                    </el-form-item>
                    <el-form-item label="边框颜色" prop="frame" :rules="[{required: true, message: '请选择边框'}]">
                        <el-select v-model="form.frame" :disabled="loading">
                            <el-option v-for="(v, k) in frame_enum" :label="v" :value="parseInt(k)"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="主要文字" prop="primary" :rules="[{required: true, message: '请输入主要文字'}]">
                        <el-input v-model="form.primary" :disabled="loading"></el-input>
                    </el-form-item>
                    <el-form-item label="次要文字" prop="secondary" :rules="[{required: true, message: '请输入次要文字'}]">
                        <el-input v-model="form.secondary" :disabled="loading"></el-input>
                    </el-form-item>
                    <el-form-item label="底部文字" prop="footer" :rules="[{required: true, message: '请输入底部文字'}]">
                        <el-input v-model="form.footer" :disabled="loading"></el-input>
                    </el-form-item>
                    <el-form-item label="标签文字" prop="mark">
                        <el-input v-model="form.mark" :disabled="loading"></el-input>
                    </el-form-item>
                    <el-form-item label="开始天数" prop="start" :rules="[{required: true, message: '请选择开始时间'},
                            { validator: (rule, value, callback) => (parseInt(value) < 0 || parseInt(value) > 30) ?
                              callback('只能选择0到30天新用户') : callback() }]">
                        <el-input v-model="form.start" :disabled="loading" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="结束天数" prop="end" :rules="[{required: true, message: '请选择结束时间'},
                            { validator: (rule, value, callback) => (form.start && value && parseInt(value) <= parseInt(form.start)) ?
                              callback('结束天数必须大于开始天数') : callback() }]">
                        <el-input v-model="form.end" :disabled="loading" type="number"></el-input>
                    </el-form-item>
<!--                    <el-form-item label="生效时间" prop="start" :rules="[{required: true, message: '请选择生效时间'}]">-->
<!--                        <el-date-picker value-format="YYYY-MM-DD" v-model="form.start" :disabled="loading"-->
<!--                                        :clearable="false"></el-date-picker>-->
<!--                    </el-form-item>-->
<!--                    <el-form-item label="截止时间" prop="start" :rules="[{required: true, message: '请选择截止时间'}]">-->
<!--                        <el-date-picker value-format="YYYY-MM-DD" v-model="form.end" :disabled="loading"-->
<!--                                        :clearable="false"></el-date-picker>-->
<!--                    </el-form-item>-->
                </el-form>
            </el-col>
            <el-col :span="4" style="text-align: center">
                <el-divider direction="vertical" style="height: 100%"></el-divider>
            </el-col>
            <el-col :span="10">
                <h3>预览</h3>
                <div style="position: relative;">
                    <img src="../../static/yellowFrame.webp" style="width: 200px" v-if="form.frame === 1">
                    <img src="../../static/redFrame.webp" style="width: 200px" v-else-if="form.frame === 2">
                    <img src="../../static/purpleFrame.webp" style="width: 200px" v-else-if="form.frame === 3">
                    <div style="position: absolute;width: 200px;height: 250px;top: 0;left: 0">
                        <div v-if="form.header" style="text-align: center;color: #fff;height: 50px;line-height: 50px">
                            {{ form.header }}
                        </div>
                        <div style="height: 100px;line-height: 110px;text-align: center;">
                            <b style="font-size: 40px">{{ form.primary }}</b>
                        </div>
                        <div style="height: 50px;text-align: center;">
                            <b v-if="form.free_trial" style="font-size: 20px;color: var(--el-text-color-placeholder)">
                                {{ form.secondary }}
                            </b>
                            <del v-else style="font-size: 28px;color: var(--el-text-color-secondary)">
                                {{ form.secondary }}
                            </del>
                        </div>
                        <div style="height: 50px;line-height: 40px;text-align: center;">
                            {{ form.footer }}
                        </div>
                    </div>
                    <div style="width: 40px;position: absolute;top: 40px;left: 150px;color: #fff;font-size: 12px;"
                         v-if="form.mark">
                        <div style="line-height: 12px;background-color: var(--el-color-danger);padding: 5px">
                            {{ form.mark }}
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update} from "../../libs/utils";

export default {
    name: "Purchase",
    data() {
        return {
            loading: false, data: [], period_enum: {1: '周', 2: '月', 3: '年'}, dialog_opened: false, editing: null,
            frame_enum: {1: 'yellow', 2: 'red', 3: 'purple'},
            form: {
                sku: '',
                // free_trial: 0,
                period: 1,
                header: '',
                frame: 1,
                primary: '',
                secondary: '',
                mark: '',
                footer: '',
                start: '',
                end: '',
                active: false,
                name: "",
                banner: "",
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/purchase/subscription?newbie=true`).then(res => {
                this.data = res.data.data.skuList;
                this.loading = false;
            });
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/purchase/subscription/${row.id}`, row).then(res => {
                this.loading = false;
            });
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                  this.editing = item;
                  update(this.form, item);
                }
            });
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/purchase/subscription/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/purchase/subscription?newbie=true`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        handleBannerSuccess(res) {
          this.loading = false;
          this.form.banner = res.data.name
        },
        handleProgress() {
          this.loading = true;
        },
    },
    mounted() {
        this.init();
    },
}
</script>

<style scoped>
.el-card ::v-deep(.el-card__header) {
    padding: 0;
}

.el-card ::v-deep(.el-card__body) {
    padding: 0;
}
</style>